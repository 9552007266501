import React, {useState} from 'react'
import emailjs from 'emailjs-com'
import {Helmet} from "react-helmet"
import Bg from '../assets/contact.jpg'

const ContactsPage = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [status, setStatus] = useState('')

    const handleSubmit = (event) => {
        event.preventDefault();
        emailjs.send('staffing','template_oSnmjtbe', {message_html: message, from_name: name, reply_to: email, to_name: 'Staffing Africa'}, 'user_G0Sp16yFFwzHCxRtLElcc')
        .then((response) => {
            setMessage('')
            setName('')
            setEmail('')
            setStatus('Enquiry sent successfully, we will get back to you ASAP')
        }, (err) => {
            setStatus('Please try sending the form again')
        }); 
    }

    return (
<>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Staffing Africa - Contact Us</title>
        <link rel="canonical" href="https://staffingfrica.com/contact-us" />
        <meta name="description" content="Get in touch with Staffing Africa" />
    </Helmet>
    <div className='bg-fixed h-64 sm:h-80 md:h-96 lg:h-112 xl:h-128' style={{backgroundImage: `url(${Bg})`, display: 'inline-block',
    width: '100%',
    backgroundSize: '100%',
    backgroundPosition: '100% 18%', backgroundRepeat: 'no-repeat'}}>
        
    </div>

    <div className='container mx-auto '>
        <div className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white 
        -mt-20 sm:-mt-24 md:-mt-32 lg:-mt-40 px-10 sm:px-20 lg:px-24 py-2'>
            Contact Us
        </div>
        <div className='pt-12 flex flex-wrap justify-between '>
            <div className='w-full lg:w-1/2 px-6'>
                <div className='px-4 sm:px-10 sm:py-6 md:px-12 lg:px-16 lg:py-12'>

                    <h1 className='py-3 sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium  leading-none'>Nairobi <span className='font-light'>Office</span></h1>
                    <div className='pb-10 text-gray-600 font-medium leading-relaxed '>
                    <span className='font-bold'>Phone: </span>+254 0705 87 36 17
                    <br/>
                    <span className='font-bold'>E-mail: </span><a href='mailto:info@staffingafrica.com'>info@staffingafrica.com</a>
                    <br/>
                    <span className='font-bold'>Location: </span>
                    Nachu Plaza 10<sup>th</sup> Floor, Kiambere Road, Off Upperhill Road
                    </div>

                    <h1 className='py-3 sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium  leading-none'>Mombasa <span className='font-light'>Office</span></h1>
                    <div className='pb-10 text-gray-600 font-medium leading-relaxed '>
                    
                    <span className='font-bold'>Phone: </span>+254 0729 68 14 27
                    <br/>
                    <span className='font-bold'>E-mail: </span><a href='mailto:info@staffingafrica.com'>info@staffingafrica.com</a>
                    <br/>
                    <span className='font-bold'>Location: </span>
                    BP Plaza 6<sup>th</sup> floor, Umoja Road, Nyali 
                    </div>


                 
                </div>
            </div>

            <div className='w-full lg:w-1/2 bg-gray-100 px-0 py-12'>
                <div className='px-10 sm:px-16'>

                    <h1 className='py-3 sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium  leading-none'>Contact<span className='font-light'> Form</span>
                    </h1>
                    <form className="bg-ourGray " onSubmit={handleSubmit}>
                            <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" >
                                        Name
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type='text'
                                    name='name'
                                    value={name}
                                    onChange={e => setName(e.target.value) }
                                    label='Enter yourName'
                                    required
                                    />
                                </div>

                                <div className="mb-4">
                                    <label className="block text-gray-700 text-sm font-bold mb-2" >
                                        Email
                                    </label>
                                    <input className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type='email'
                                    name='email'
                                    value={email}
                                    onChange={e => setEmail(e.target.value) }
                                    label='enter your Email'
                                    required
                                    />
                                </div>
                                    <label className="block text-gray-700 text-sm font-bold mb-2" >
                                        Enquiry
                                    </label>
                                    <textarea className="h-32 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type='text'
                                    name='message'
                                    value={message}
                                    onChange={e => setMessage(e.target.value) }
                                    label='Enter enquiry'
                                    required
                                    />

                                <div className="mt-6 flex-wrap items-center justify-between">
                                    <button className="bg-logoPurple hover:bg-logoOrange text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type='submit'>Send Enquiry</button>
                                    <h5 className='py-6 px-6 text-logoOrange'>{status}</h5>
                                </div>
                                
                            </form>
                </div>

            </div>
        </div>
        {/* <div className='pt-12 flex flex-wrap justify-between bg-grayBg'>
            
        </div> */}
    </div>

</>
)}

export default ContactsPage