import React from 'react';
import {Helmet} from "react-helmet"
import Flickity from 'react-flickity-component'
import { FiAlertOctagon, FiAward, FiActivity, FiCheckCircle, FiLink, FiRefreshCcw, FiBriefcase, FiShare2, FiCheck, FiHelpCircle, FiCircle, FiZap } from 'react-icons/fi'

const flickityOptions = {
     initialIndex: 0,
     fade: true,
     pauseAutoPlayOnHover: false,
     prevNextButtons: true,      
     autoPlay: 4000,
     wrapAround: true,
     fullscreen: true,
     adaptiveHeight: true,
     pageDots: false,
     imagesLoaded: true 
 }


const HomePage = () => (
  <>
     <Helmet>
          <meta charSet="utf-8" />
          <title>Staffing Africa - Home</title>
          <link rel="canonical" href="https://staffingfrica.com/" />
          <meta name="description" content="At Staffing Africa, we are leaders in supply and management of outsourced Manpower for Admin support, institutions, factories, warehouses and projects." />
     </Helmet>

     <div className='w-auto  h-72 sm:h-96 md:h-112 lg:h-144 xl:h-full pt-20 sm:pt-8 lg:pt-0   overflow-hidden'>
          <Flickity
               className={'carousel'} // default ''
               elementType={'div'} // default 'div'
               options={flickityOptions} // takes flickity options {}
               disableImagesLoaded={false} // default false
               reloadOnUpdate // default false
               static // default false
          >
               <div style={{ width: '100%', height: '711px'}}>
                    <div className='absolute w-2/5 mx-auto px-10 sm:px-16 lg:px-20 xl:px-20 pt-16 sm:pt-24 xl:pt-32 mt-8 sm:mt-20 md:mt-24 lg:pt-32'>

                         <h2 className='text-logoPurple2 text-base sm:text-xl font-semibold pb-8 lg:text-4xl xl:text-5xl leading-5 xl:leading-none md:text-2xl'>Time Saving</h2>
                         <p className='hidden lg:inline-block text-gray-600 font-normal pb-10'>Outsourcing recruitment means getting the right person for the right job in a time efficient way. Saving you time in hiring and training means more time to increase your output.</p>
                         {/* <a href='/contact-us' className='mt-4 rounded-full py-4 px-6 text-white hover:text-black transition duration-500 ease-in-out bg-logoPurple2 hover:bg-logoOrange2 transform hover:-translate-y-1 hover:scale-110'>   Enquire Now   </a> */}
                    </div>
                    <img src='/slider5.png' alt='Staffing Africa'/>
               </div>
               
               <div style={{ width: '100%', height: '711px'}}>
                    <div className='absolute w-2/5 mx-auto px-10 sm:px-16  lg:px-20 xl:px-20 pt-16 sm:pt-24 xl:pt-32 mt-8 sm:mt-20 md:mt-24 lg:mt-32'>
                         <h2 className='text-logoPurple2 text-base sm:text-xl font-semibold pb-8 lg:text-4xl xl:text-5xl leading-5 xl:leading-none md:text-2xl'>Increase Output</h2>
                         <p className='hidden lg:inline-block text-gray-600 font-normal'>he resources, time and money saved are been utilized in other important tasks and employer can use his saved time in concentrating more on core of the business, which ultimately increases the total output of the staff and organization both.</p>
                         {/* <a href='/contact-us' className='mt-10 rounded-full py-4 px-6 text-white hover:text-black transition duration-500 ease-in-out bg-logoPurple2 hover:bg-logoOrange2 transform hover:-translate-y-1 hover:scale-110'>   Enquire Now   </a> */}
                    </div>
                    <img src='/slider3.png' alt='Staffing Africa'/>
               </div>

               {/* <div style={{ width: '100%', height: '711px'}}>
                    <div className='absolute w-2/5 mx-auto px-10 sm:px-16  lg:px-20 xl:px-20 pt-16 sm:pt-24 xl:pt-32 mt-8 sm:mt-20 md:mt-24 lg:mt-32'>
                         <h2 className='text-logoPurple2 text-base sm:text-xl font-semibold pb-8 lg:text-4xl xl:text-5xl leading-5 xl:leading-none md:text-2xl'>Cost Effective</h2>
                         <p className='hidden lg:inline-block text-gray-600 font-normal'>Eliminate the need for advertisements, arranging interview sessions, salary headaches, training programs and concentrate on your core business thereby saving company resources and money.</p>
                    </div>
                    <img src='/slider3.png' alt='Staffing Africa'/>
               </div> */}

               <div style={{ width: '100%', height: '711px'}}>
                    <div className='absolute w-2/5 mx-auto px-10 sm:px-16  lg:px-20 xl:px-20 pt-16 sm:pt-24 xl:pt-32 mt-8 sm:mt-20 md:mt-24 lg:mt-32'>
                         <h2 className='text-logoPurple2 text-base sm:text-xl font-semibold pb-8 lg:text-4xl xl:text-5xl leading-5 xl:leading-none md:text-2xl'>Legal Disputes</h2>
                         <p className='hidden lg:inline-block text-gray-600 font-normal'>Outsourcing indemnifies the company against compensation claims. It also reduces the company's exposure to legal</p>
                         {/* <a href='/contact-us' className='mt-10 rounded-full py-4 px-6 text-white hover:text-black transition duration-500 ease-in-out bg-logoPurple2 hover:bg-logoOrange2 transform hover:-translate-y-1 hover:scale-110'>   Enquire Now   </a> */}
                    </div>
                    <img src='/slider2.png' alt='Staffing Africa'/>
               </div>
               
               <div style={{ width: '100%', height: '711px'}}>
                    <div className='absolute w-2/3 mx-auto px-10 sm:px-16  lg:px-20 xl:px-20 pt-16 sm:pt-24 xl:pt-32 mt-8 sm:mt-20 md:mt-24 lg:mt-32'>
                         <h2 className='text-logoPurple2 text-base sm:text-xl font-semibold lg:text-4xl xl:text-5xl  leading-none sm:leading-5 md:leading-none md:text-2xl'>
                         Recruitement,<br/>Supply, <br/>Management of<br/>Outsourced<br/>Manpower
                         </h2>
                         {/* <a href='/contact-us' className='mt-4 rounded-full py-4 px-6 text-white hover:text-black transition duration-leading 500 ease-in-out bg-logoPurple2 hover:bg-logoOrange2 transform hover:-translate-y-1 hover:scale-110'>   Enquire Now   </a> */}
                    </div>
                    <img src='/slider.png' alt='Staffing Africa'/>
               </div>
               
               
          </Flickity>
    </div>

    <div className='container mx-auto xl:mx-auto py-8 px-10 sm:py-10 sm:px-10 md:py-16  md:px-16 xl:py-20  xl:px-20' 
    style={{background: 'linear-gradient(120deg, #e4b040 40%, #ff8b00 100%)'}}>
          <div className='text-lg sm:text-xl md:text-2xl lg:text-3xl xl:text-4xl text-center leading-none'>
               Welcome to 
               <span className='text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-medium'> Staffing Africa</span>, leaders in 
               <span className='font-medium text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl'> supply</span> and <span className='xl:font-medium text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl'> management</span> of 
               <span className='text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl'> Outsourced    
               </span>
               <span className='text-xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-medium'>  Manpower.    
               </span>
          </div>

    </div>

    <div className='bg-gray-200 container mx-auto lg:px-16 lg:py-16 xl:px-20 xl:py-20' >
          <div className='flex flex-wrap lg:flex-no-wrap justify-between my-8'>
               <div className='bg-white lg:rounded-lg lg:shadow w-full lg:w-1/2'>
                    <div className=' px-10 py-4'>
                         <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium '>Staff 
                         <span className='font-light'> Recruitment</span></h1>
                         <div className='text-gray-600 font-medium leading-7 lg:leading-loose'>
                         At Staffing Africa Ltd, recruitment means ensuring the 'Best Fit'. We take time to understand our clients' needs- the competencies, skills, experiences, aptitude required for the position in order to create the most suitable match. This enables us to match client's needs with job seekers experience, achievements, aptitude, aspirations, and track record.
                         </div>
                    </div>
               </div>
               <div className='bg-white lg:rounded-lg lg:shadow w-full lg:w-1/2 lg:ml-3'>
                    <div className=' px-10 py-4'>
                         <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium leading-none'>Outsourced Labour<span className='font-light'> Management</span></h1>
                         <div className='text-gray-600 font-medium leading-7 lg:leading-loose'>
                         We provide and manage qualified and suitable staff to meet corporate short and long term labour requirements. We recruit, train, place, insure and handle all the HR functions of the outsourced staff. We are well equipped to facilitate the staff at short notice and redeploy them when they are no longer needed by our Principals.
                         </div>
                    </div>
               </div>
          </div>
    </div>

    <div className='container mx-auto px-10 py-4 lg:px-16 lg:py-16 xl:px-20 xl:py-20' >
          

          <div className=' xl:leading-10 xl:px-2'>
               <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium'>
                    Why Partner With Us?
               </h1>
               <h2 className='text-gray-600 font-light text-lg pb-2'>
                    In partnering with us, you will benefit from the following list of endless possibilities;
               </h2>
               
          </div>

          <div className='flex flex-wrap justify-between'>
               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>
                         <div className='text-center'>
                              <FiLink className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                              Hands on directors with full understanding of corporate HR recruitment and outsourcing requirements.
                         </div>
                    </div>
               </div>
               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>
                         <div className='text-center'>
                              <FiBriefcase className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                              
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                              Use of quality products and tools in performance of our tasks.
                         </div>
                    </div>
                    
               </div>
               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>
                         <div className='text-center'>
                              <FiCheckCircle className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                              
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                         Adherence to strict safety guidelines.
                         </div>
                    </div>
                    
               </div>
               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>
                         <div className='text-center'>
                              <FiAward className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                              
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                         Professional staff recruitment procedures that ensure that the best candidates are hired.
                         </div>
                    </div>
                    
               </div>

               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>
                         <div className='text-center'>
                              <FiActivity className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                         Continuous staff training on operations and occupational safety and health in the workplace.
                         </div>
                    </div>
                    
               </div>
               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>
                         <div className='text-center'>
                              <FiRefreshCcw className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                              
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                         Tailor made service delivery to meet customer demands.
                         </div>
                    </div>
                    
               </div>
               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>

                         <div className='text-center'>
                              <FiZap className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                              
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                         Quick response to incidents and clients complaints. Investigations will be undertaken immediately.
                         </div>
                    </div>
               </div>
               <div className='w-full self-auto md:w-1/2 xl:w-1/4 p-2 text-purple-200'>
                    <div className='mr-2 h-full bg-purple-800 hover:bg-purple-700 rounded-lg shadow items-center p-4'>
                         <div className='text-center'>
                              <FiShare2 className='inline mx-1 text-purple-200 h-20 w-20 my-4'/>
                              
                         </div>
                         <div className='px-6 pb-4 xl:px-3 xl:pb-2'>
                         Action taken, learning points noted / documented and shared.
                         </div>
                    </div>
                    
               </div>

          </div>

          

    </div>

    <div className='container mx-auto  py-10 sm:py-16 lg:py-20 px-10 sm:px-16 lg:px-20' 
    style={{background: 'linear-gradient(120deg, #e4b040 0%, #ff8b00 100%)'}}>
          <div className='text-center leading-none'>
               <h2 className='pb-4 md:pb-6 lg:pb-10 text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-medium'> Core Industries </h2>
               <div className='flex flex-wrap justify-center sm:justify-between '>
                    <div className='w-4/5 self-auto sm:w-1/2 lg:w-1/3 xl:w-1/6  p-2'>
                         <img className='h-auto object-cover rounded-lg shadow' src={ require('../assets/manufacturing.jpg') } alt='Staffing Africa'/>
                         <div className='bg-logoOrange -mt-40 xl:-mt-24 absolute shadow'>
                              <p className=' p-2 text-black text-lg w-1/2'> Manufacturing</p>
                         </div>
                    </div>
                    <div className='w-4/5 self-auto sm:w-1/2 lg:w-1/3 xl:w-1/6  p-2'>
                         <img className='h-auto object-cover rounded-lg shadow' src={ require('../assets/construction.jpg') } alt='Staffing Africa'/>
                         <div className='bg-logoOrange -mt-40 xl:-mt-24 absolute shadow'>
                              <p className=' p-2 text-black text-lg w-1/2'> Construction</p>
                         </div>
                    </div>
                    <div className='w-4/5 self-auto sm:w-1/2 lg:w-1/3 xl:w-1/6  p-2'>
                         <img className='h-auto object-cover rounded-lg shadow' src={ require('../assets/education.jpg') } alt='Staffing Africa'/>
                         <div className='bg-logoOrange -mt-40 xl:-mt-24 absolute shadow'>
                              <p className=' p-2 text-black text-lg w-1/2'> Education</p>
                         </div>
                    </div>
                    <div className='w-4/5 self-auto sm:w-1/2 lg:w-1/3 xl:w-1/6  p-2'>
                         <img className='h-auto object-cover rounded-lg shadow' src={ require('../assets/aviation.jpg') } alt='Staffing Africa'/>
                         <div className='bg-logoOrange -mt-40 xl:-mt-24 absolute shadow'>
                              <p className=' p-2 text-black text-lg w-1/2'> Aviation</p>
                         </div>
                    </div>
                    <div className='w-4/5 self-auto sm:w-1/2 lg:w-1/3 xl:w-1/6  p-2'>
                         <img className='h-auto object-cover rounded-lg shadow' src={ require('../assets/energy.jpg') } alt='Staffing Africa'/>
                         <div className='bg-logoOrange -mt-40 xl:-mt-24 absolute shadow'>
                              <p className=' p-2 text-black text-lg w-1/2'> Energy</p>
                         </div>
                    </div>
                    <div className='w-4/5 self-auto sm:w-1/2 lg:w-1/3 xl:w-1/6  p-2'>
                         <img className=' h-auto object-cover rounded-lg shadow' src={ require('../assets/food.jpg') } alt='Staffing Africa'/>
                         <div className='bg-logoOrange -mt-40 xl:-mt-24 absolute shadow'>
                              <p className=' p-2 text-black text-lg w-1/2'> Food</p>
                         </div>
                    </div>
               </div>

          </div>



    </div>

  </>
);

export default HomePage;
