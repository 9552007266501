import React from 'react';
import {Helmet} from "react-helmet"
import Bg from '../assets/working-2.jpg'
import { FiLayers, FiGrid, FiCompass } from 'react-icons/fi'

const AboutPage = () => (
<>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Staffing Africa - About Us</title>
        <link rel="canonical" href="https://staffingfrica.com/about-us" />
        <meta name="description" content="Staffing Africa is a sister company to Manpower Networks Ltd providing outsourced manpower services. We provide comprehensive Human Resource Management Solutions to various corporate companies." />
    </Helmet>
    <div className='bg-fixed h-64 sm:h-80 md:h-96 lg:h-112 xl:h-128' style={{backgroundImage: `url(${Bg})`, display: 'inline-block',
    width: '100%',
    backgroundSize: '100%',
    backgroundPosition: '100% 18%', backgroundRepeat: 'no-repeat'}}>
        
    </div>

    <div className='container mx-auto '>
        <div className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white 
        -mt-20 sm:-mt-24 md:-mt-32 lg:-mt-40 px-10 sm:px-20 lg:px-24 py-2'>
            About Us
        </div>

        <div className='pt-1 sm:pt-10 lg:pt-10 lg:mt-1 flex flex-wrap justify-between '>
            <div className='w-full lg:w-1/2 px-6'>
                <div className='px-4 py-12 sm:px-16 sm:py-10 lg:px-16 lg:py-20'>
                    <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium '>What <span className='font-light'>We Do</span></h1>
                    <div className='pb-6 text-gray-600 font-medium leading-relaxed '>Staffing Africa is a sister company to Manpower Networks Ltd providing outsourced manpower services. We provide comprehensive Human Resource Management Solutions to various corporate companies.</div>

                    <div className='lg:pb-6 text-gray-600 font-medium leading-relaxed '>
                    We believe in excellent product and service delivery to our customers, that meets their expectation and endeavor to surpass their expectations. Our drive to service delivery includes:
                        <div className='py-6 flex flex-wrap justify-center'>
                            <div className='w-full text-center pb-6 '>
                                <FiLayers className='mt-1 p-3 bg-logoPurple2 rounded-lg inline mx-1 text-white h-16 w-16 '/>
                            </div>
                            <div className='w-full lg:flex-1 px-4 italic text-center text-gray-600  text-base'>
                            Experience and methodologies to perform the work effectively and efficiently: Our approach uses tried and tested HR recruitment and outsourcing services that help to identify and explain issues. Our approach focuses on building consensus and decision- taking quickly.
                            </div>
                        </div>
                        <div className='py-6 flex flex-wrap'>
                            <div className='w-full text-center pb-6 '>
                                <FiGrid className='mt-1 p-3 bg-logoPurple2 rounded-lg inline mx-1 text-white h-16 w-16 '/>
                            </div>
                            <div className='flex-1 px-4 italic text-center text-gray-600  text-base'>
                            A team of specialists focused on similar work: Depending on the nature of the assignment, you will require a team of specialists working under very proactive management and time scales to ensure that your HR requirements run in parallel and are nonetheless properly integrated.
                            </div>
                        </div>
                        <div className='py-10 flex flex-wrap'>
                            <div className='w-full text-center pb-6 '>
                                <FiCompass className='mt-1 p-3 bg-logoPurple2 rounded-lg inline mx-1 text-white h-16 w-16 '/>
                            </div>
                            <div className='flex-1 px-4 italic text-center text-gray-600  text-base'>
                            Comprehensive understanding of the assignment's objectives and surrounding environment: Our team combines extensive research on common and modern labor practices and have extensive knowledge of the current labor market, with regards to ethical HR recruitment and outsourcing services.
                            </div>
                        </div>



                    </div>


                    
                </div>
            </div>

            <div className='bg-gray-200 w-full lg:w-1/2 px-6'>
                <div className='px-4 py-12 sm:px-16 sm:py-10 lg:px-16 lg:py-20'>
                    <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium '>Our <span className='font-light'>Commitment</span></h1>
                    <div className='pb-6 text-gray-600 font-medium leading-relaxed '>We are committed to promoting high standards of discipline, excellence and ethics through adherence to both our quality standards and those of our clients. We believe in recruiting and retaining sound, professional support staff, as well as providing training and opportunities to all our staff for growth in a stimulating and enabling environment. We are dedicated to maintaining a strong internal administration, thereby enabling provision of quality service and ensuring client satisfaction.<br/><br/>

                    We believe relationships are our top priority, and we strongly believe that good relationships with our clients are a per-requisite to success.
                    Our people are dedicated to devising and implementing innovative, client-based solutions that lead to the better utilization of resources and improvement of performance and delivery.</div>

                    <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium '>Our <span className='font-light'>Vision</span></h1>
                    <div className='pb-6 text-gray-600 font-medium leading-relaxed '>To become the regional leader in the provision of HR recruitment and related outsourcing services.</div>


                    <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium '>Our <span className='font-light'>Mission</span></h1>
                    <div className='pb-6 text-gray-600 font-medium leading-relaxed '>To provide excellent customer driven, cost effective HR recruitment and related outsourced services for organization productivity and growth.</div>


                    <h1 className='sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium '>Our <span className='font-light'>Promise</span></h1>
                    <div className='pb-6 text-gray-600 font-medium leading-relaxed '>A collaborative relationship
                    Reliable and professional expertise and viable profitable solutions
                    Leading edge Hr recruitment and outsourcing solutions</div> 
                    </div>

            </div>
        </div>
    </div>

</>
)

export default AboutPage