import React from 'react';
import {Helmet} from "react-helmet"
import Bg from '../assets/solutions.jpg'

const SolutionsPage = () => (
<>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Staffing Africa - Solutions</title>
        <link rel="canonical" href="https://staffingfrica.com/solutions" />
        <meta name="description" content="We provide and manage qualified and suitable staff to meet corporate short and long term labour requirements" />
    </Helmet>
    <div className='bg-fixed h-64 sm:h-80 md:h-96 lg:h-112 xl:h-128' style={{backgroundImage: `url(${Bg})`, display: 'inline-block',
    width: '100%',
    backgroundSize: '100%',
    backgroundPosition: '100% 18%', backgroundRepeat: 'no-repeat'}}>
        
    </div>

    <div className='container mx-auto '>
        <div className='text-3xl sm:text-4xl md:text-5xl lg:text-6xl text-white 
        -mt-20 sm:-mt-24 md:-mt-32 lg:-mt-40 px-10 sm:px-20 lg:px-24 py-2'>
            Our Solutions
        </div>
        <div className=' flex flex-wrap justify-between px-4 py-12 sm:px-16 sm:py-10 lg:px-16 lg:py-20'>
            <div className='w-full lg:w-1/2 px-6'>
                <div className='lg:pt-12'>

                    <h1 className='py-3 sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium  leading-none'>Outsourced Manpower<span className='font-light'>Management Solutions</span></h1>
                    <div className='pb-10 text-gray-600 font-medium leading-relaxed '>We provide and manage qualified and suitable staff to meet corporate short and long term labour requirements. We recruit, train, place, insure and handle all the HR functions of the outsourced staff. We are able to provide the staff at short notice and redeploy them when they are no longer needed by our Principals.</div>
                 
                </div>
            </div>

            <div className='w-full lg:w-1/2 px-6 order-first lg:order-last'>
                <div className='px-10 py-4 lg:px-10  lg:pt-16'>
                    <img src={require('../assets/outsourced.png')} alt='outsourced labor management' />

                </div>

            </div>
        </div>
        <div className='flex flex-wrap justify-between bg-grayBg  px-4 py-12 sm:px-16 sm:py-10 lg:px-16 lg:py-20'>
            <div className='w-full lg:w-1/2 px-6'>
                <div className='px-10 py-4 lg:px-10  lg:pt-16'>

                    <img src={require('../assets/rectuitment.png')}  alt='recruitment'/>
                 
                </div>
            </div>

            <div className='w-full lg:w-1/2 px-6'>
                <div className=''>
                <h1 className='py-3 sm:py-2 md:py-3 text-2xl md:text-3xl lg:text-4xl xl:text-5xl text-logoPurple2 font-medium  '>Recruitment <span className='font-light'>Solutions</span></h1>
                    <div className='pb-10 text-gray-600 font-medium leading-relaxed '>We engage in selection, recruitment and placement of right people for the right jobs. We counsel our job seekers about our client's business, industry, and their expectations. Just to mention a few, receptionists, clerks, book keepers, accountants, sales and factory staff, supervisors and managers.</div>

                </div>

            </div>
        </div>
    </div>

</>
)

export default SolutionsPage