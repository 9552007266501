import React, {useState}from 'react';
import { Link } from 'react-router-dom';

//import { ReactComponent as LogoWhite } from '../../assets/logo-white.svg';


const Footer = () => (
    <div className='pb-24' style={{background: 'linear-gradient(90deg, #1b0d2b 30%, #30184c 100%)'}}>
        <div className='container mx-auto px-8 py-10 sm:py-10 sm:px-24'>
            <div className='flex flex-wrap items-start content-center'>

                <div className='h-32 w-full my-2 lg:w-1/2'>
                    <img src={require('../assets/logo-white.svg')} alt='logo'  className='w-5/6 pt-3'/>
                    <p className='text-purple-200 text-sm py-4'> &copy; 2020 Staffing Africa Limited.
                        All rights resevered.
                        <br/><a className='text-purple-500 text-xs hover:text-purple-200 py-4' href='https://evokemedia.co.ke' target='_blank'>Designed by Evoke Media.</a></p> 
                </div>
                
                

                <div className='w-full pt-10 lg:py-4 lg:w-1/2 text-justify text-purple-200 text-sm '>
                    <h2 className='text-2xl pt-2 '>Office Locations</h2>
                    <span className='font-bold text-purple-400 '>Nairobi Office: </span>Nachu Plaza 10<sup>th</sup> Floor, No. 15 Kiambere Road, Off Upperhill Road
                    <br/><br/> <span className='font-bold text-purple-400 '>Mombasa Office: </span>
                    BP Plaza 6<sup>th</sup> floor, Umoja Road, Nyali         
                </div>

                {/* <div className='w-full py-4 text-center lg:w-1/3 lg:text-justify lg:pl-6 '>
                    <h4 className='text-sm text-purple-200 py-2'>Follow us on Social Media</h4>
                    <div className='mx-auto flex justify-center items-center lg:justify-start'>
                        <a href='http://twitter.com/sichey' className="px-2 text-purple-500 hover:text-purple-200 focus:outline-none focus:text-logoPuple " target='_blank'>
                            <svg className="h-8 w-8 fill-current" viewBox="0 0 24 24" ><path d="M24 4.6c-1 .4-1.9.6-2.9.7a5 5 0 002.2-2.7c-1 .6-2 1-3.1 1.2-1-1-2.2-1.6-3.6-1.6a5 5 0 00-4.8 6 14 14 0 01-10.2-5c-.4.7-.6 1.5-.6 2.4a5 5 0 002.2 4.1c-.8 0-1.6-.2-2.3-.6A5 5 0 005 14a5 5 0 01-2.2 0 5 5 0 004.6 3.5 9.9 9.9 0 01-7.3 2 14 14 0 007.6 2.3c9 0 14-7.5 14-14V7A10 10 0 0024 4.6z"/></svg>
                        </a>
                        <a href='http://facebook.com/Sichey-Automotive-East-Africa-Limited-114950766905508' target='_blank' className="px-2 text-purple-500 hover:text-purple-200 focus:outline-none focus:text-logoPuple ">
                        <svg className="h-8 w-8 fill-current" viewBox="0 0 24 24" ><path d="M24 12a12 12 0 10-13.9 11.9v-8.4h-3V12h3V9.4c0-3 1.8-4.7 4.6-4.7l2.6.2v3h-1.5c-1.5 0-2 .9-2 1.8V12h3.4l-.5 3.5h-2.8v8.4A12 12 0 0024 12z"/></svg>
                        </a>
                        <a href='http://linkedin.com/company/sichey-automotive-east-africa-limited' target='_blank' className="px-2 text-purple-500 hover:text-purple-200 focus:outline-none focus:text-logoPuple ">
                        <svg className="h-8 w-8 fill-current" viewBox="0 0 24 24" ><path d="M20.4 20.5H17v-5.6c0-1.3 0-3-1.9-3-1.8 0-2 1.4-2 2.9v5.7H9.3V9h3.4v1.6c.5-1 1.6-1.9 3.4-1.9 3.6 0 4.2 2.4 4.2 5.5v6.3zm-15-13a2 2 0 110-4.2 2 2 0 010 4.1zm1.7 13H3.6V9H7v11.5zM22.2 0H1.8C.8 0 0 .8 0 1.7v20.6c0 1 .8 1.7 1.8 1.7h20.4c1 0 1.8-.8 1.8-1.7V1.7c0-1-.8-1.7-1.8-1.7z"/></svg>
                        </a>
                    </div>
                </div> */}
               
            </div>

        </div>
    </div>

);

export default Footer;
